import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from 'src/app/services/config.service';
import { LibraryService } from '../../../services/library.service';
import { Router } from '@angular/router';
import { SearchService } from 'src/app/services/search.service';
import { Author, Book } from 'src/app/types/supabase/supabase.models';
import { TextService } from 'src/app/services/text.service';
import { EventsService } from 'src/app/services/events.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: [
        './home.component.scss'
    ]
})
export class HomeComponent implements OnInit {
    public searchValue: string | undefined = '';

    public title?: string;
    public latestBook?: Book;
    public spotlightBook?: Book | null;
    
    public loaded: boolean = false;

    public events: any[] = [];

    public introText?: {
        title: string;
        postAuthor: string;
        created_at: string;
        text: string;
    };

    constructor(
        private configurationService: ConfigurationService,
        private textService: TextService,
        private eventsService: EventsService,
        private libraryService: LibraryService,
        private searchService: SearchService,
        private router: Router
    ) { }

    public async ngOnInit(): Promise<void> {
        this.title = await this.configurationService.getHomepageTitle();

        this.latestBook = await this.libraryService.getLatestBook();
        const highlightedBookId = await this.configurationService.getHighlightedBook();
        this.spotlightBook = await this.libraryService.getBook(highlightedBookId);

        this.introText = await this.textService.getHomeText();
        this.events = await this.eventsService.getEvents();

        this.loaded = true;
    }

    public authorNames(authors: Author[]): string[] {
        return authors.map(author => author.name);
    }

    public goToBook(id: number): void {
        this.router.navigate(['boeken', 'id', id]);
    }

    public getImageUrl(name: string): string {
        return this.libraryService.getBookCoverUrl(name);
    }

    public onSearch(): void {
        this.searchService.searchText(this.searchValue, true);
    }

    public getDate(date: string): Date {
        return new Date(date);
    }
}
