import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
    selector: 'ui-svg',
    templateUrl: './svg.component.html',
    styleUrls: [
        './svg.component.scss'
    ]
})
export class SvgComponent implements OnInit {
    @Input() name: string = '';
    @Input() spin: boolean = false;
    @Input() color?: string;
    @Input() width?: number;
    @Input() height?: number;

    public styles: any = {
        height: '24px'
    };

    constructor(
    ) { }

    public ngOnChanges(changes: SimpleChanges): void {
        // if (changes['color']) {
        //     this.styles['color'] = changes['color'].currentValue;
        // }    
    }

    public ngOnInit(): void {
    }
}
