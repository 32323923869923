import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { SessionService } from './services/session.service';

@Component({
    template: '<router-outlet></router-outlet>'
})
export class MainComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private sessionService: SessionService
    ) {}
  
    async ngOnInit(): Promise<void> {
        if (this.route.snapshot.fragment) {
            const parts = this.route.snapshot.fragment.split('&');
            const keyValues: any = {};
            parts.forEach(part => {
                const splitPart = part.split('=');
                keyValues[splitPart[0]] = splitPart[1];
            });
            localStorage.setItem('token', keyValues['access_token']);
        } 
        
        await this.sessionService.getUser();
        const user = firstValueFrom(this.sessionService.user$);
        if (!user) {
            localStorage.removeItem('token');
        }
    }
}
