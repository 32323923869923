<div class="container p-0 p-lg-3 flex-column align-items-center">
    <app-breadcrumbs *ngIf="breadcrumbs" [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
    <div class="d-flex flex-row">
        <div id="sidebar" class="sidebar d-none d-lg-flex flex-column w-25 pt-5">
            <app-books-filter></app-books-filter>
        </div>

        <div id="content" class="w-100 w-lg-75 ms-0 ms-lg-5">

            <div class="mb-1 mb-md-5">
                <h1>Boeken</h1>
            </div>

            <div class="d-flex flex-column d-lg-none mb-5">
                <!-- <app-books-filter [allTags]="allTags" [allLanguages]="allLanguages" [allPublishers]="allPublishers"
                    [filter]="filter" (searchSubmitted)="searchAllValues()"
                ></app-books-filter> -->
            </div>

            <app-books-list></app-books-list>
        </div>
    </div>
    
</div>