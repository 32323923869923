<div class="container p-3">
    <app-breadcrumbs *ngIf="breadcrumbs" [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
    <ng-container *ngIf="!completed; else thanks">
        <h1>Registreer</h1>
        <div class="flex-column" *ngIf="!completed; else thanks">
            <form [formGroup]="form" class="login-form pb-4" novalidate>        
                <label for="username" class="form-label mt-3">Naam*</label>
                <input type="text" class="form-control" formControlName="username" (focus)="usernameFocused = true" (blur)="usernameFocused = false">
                <p *ngIf="usernameFocused"><small>We gebruiken dit in de communicatie wanneer je een boek ontleent.</small></p>
                <div class="row">
                    <div class="col-lg-6">
                        <label for="email" class="form-label mt-3">E-mailadres*</label>
                        <input type="email" class="form-control" formControlName="email" (focus)="emailFocused = true" (blur)="emailFocused = false">
                        <p *ngIf="emailFocused"><small>We mailen naar dit adres wanneer je een boek ontleent.</small></p>
                    </div>
                    <div class="col-lg-6">
                        <label for="phone" class="form-label mt-3">Telefoon</label>
                        <input type="text" class="form-control" formControlName="phone" (focus)="phoneFocused = true" (blur)="phoneFocused = false">    
                        <p *ngIf="phoneFocused"><small>We gebruiken dit als communicatiemiddel voor herinneringen of voor het beantwoorden van vragen e.d.</small></p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-6">
                        <label for="password" class="form-label mt-3">Wachtwoord*</label>
                        <input type="password" class="form-control" formControlName="password" (focus)="passwordFocused = true" (blur)="passwordFocused = false">
                        <p *ngIf="passwordFocused"><small>Kies een wachtwoord met minimum 6 karakters.</small></p>
                    </div>
                    <div class="col-lg-6">
                        <label for="repeatPassword" class="form-label mt-3">Herhaal wachtwoord*</label>
                        <input type="password" class="form-control" [class.invalid-field]="showPasswordWarning" formControlName="repeatPassword" (blur)="checkPassword()" (focus)="passwordFocused = true" (blur)="passwordFocused = false">
                    </div>
                </div>
            </form>
            
            <div class="container d-flex flex-column align-items-center justify-content-center my-3">
                <button type="button" class="btn btn-lg btn-primary" [class.disabled]="!form.valid" (click)="submit()">Registreer</button>
            </div>
        </div>
    </ng-container>
    <ng-template #thanks>
        Top! Bekijk zeker je mailbox om je account te bevestigen.
    </ng-template>
</div>
