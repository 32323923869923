<div class="container p-3 ">
    <h1>Vraag een nieuw wachtwoord aan</h1>
    <div class="d-flex flex-column justify-content-center align-items-center">
        <form [formGroup]="form" class="w-100 w-md-50 w-xl-33">
            <label for="email" class="form-label mt-3">Email</label>
            <div class="input-group">
                <input type="email" class="form-control" formControlName="email">
            </div>
        </form>

        <div class="container d-flex flex-column align-items-center justify-content-center my-3">
            <ui-button context="primary" (clicked)="confirm()" [disabled]="loading">Vraag nieuw wachtwoord aan</ui-button>
        </div>

        <p *ngFor="let alert of alerts">
            <ngb-alert [type]="alert.type" (closed)="close(alert)">{{ alert.message }}</ngb-alert>
        </p>
    </div>
</div>