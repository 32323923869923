import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TagsService } from '../services/tags.service';
import { Tag } from '../types/supabase/supabase.models';

@Injectable({ providedIn: 'root' })
export class TagsResolver  {
  constructor(private tagsService: TagsService) {}

  resolve = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Tag[]> => {
    return await this.tagsService.getAllTags();
  }
}