import { Injectable } from '@angular/core';
import { SupabaseService } from './supabase.service';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService   {
    constructor(private supabaseService: SupabaseService) {}

    public async getHighlightedBook(): Promise<number> {
        const result = await this.supabaseService.client.from('configs')
            .select('value')
            .eq('id', 1)
            .limit(1)
            .single();

        return parseInt(result.data?.value);
    }

    public async getHomepageTitle(): Promise<string> {
        const result = await this.supabaseService.client.from('configs')
            .select('value')
            .eq('id', 2)
            .limit(1)
            .single();

        return result.data?.value;
    }

    public async getBookRequestText(): Promise<string> {
        const result = await this.supabaseService.client.from('configs')
            .select('value')
            .eq('id', 3)
            .limit(1)
            .single();

        return result.data?.value;
    }
}