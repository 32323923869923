import { Component } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { RegisterService } from 'src/app/services/register.service';

const passwordMatchValidator: ValidatorFn = (group: { get: Function }): ValidationErrors | null => {
    const passwordControl = group.get('password');
    const confirmPasswordControl = group.get('repeatPassword');

    if (!passwordControl || !confirmPasswordControl) {
      return null;
    }

    return passwordControl.value === confirmPasswordControl.value ? null : { 'mismatch': true };
}

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent {
    public completed = false;
    public usernameFocused = false;
    public emailFocused = false;
    public passwordFocused = false;
    public phoneFocused = false;
    public showPasswordWarning = false;

    public breadcrumbs = [
        { label: 'Registreer' }
    ];

    constructor(private registerService: RegisterService) { }

    public form = new FormGroup({
        username: new FormControl('', [Validators.required, Validators.minLength(5)]),
        phone: new FormControl('', []),
        email: new FormControl('', [Validators.required, Validators.email, Validators.minLength(6)]),
        password: new FormControl('', [Validators.required, Validators.minLength(6)]),
        repeatPassword: new FormControl('', [Validators.required, Validators.minLength(6)])
    }, { validators: passwordMatchValidator, updateOn: 'blur' });

    public submit(): void {
        if (this.form.valid) {
            this.form.disable();
            this.signup();
        } else {
            // this.form.class
        }
    }

    private signup(): void {
        const request = {
            username: this.form.controls['username'].value,
            password: this.form.controls['password'].value,
            email: this.form.controls['email'].value,
            phone: this.form.controls['phone'].value
        }
        this.registerService.signUpRegular(request)
            .then(() => {
                this.form.reset();
                this.completed = true;
            });
    }

    public checkPassword(): void {
        const passwordControl = this.form.get('password');
        const confirmPasswordControl = this.form.get('repeatPassword');

        this.showPasswordWarning = passwordControl?.value !== confirmPasswordControl?.value;
    }
}
