import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User, Signup } from '../types';
import { SupabaseService } from './supabase.service';

@Injectable({
    providedIn: 'root'
})
export class RegisterService {
    public user$: BehaviorSubject<User|undefined> = new BehaviorSubject<User|undefined>(undefined);

    constructor(
        private supabaseService: SupabaseService
    ) {}

    public signUpRegular(formValues: any): Promise<any> {
        return this.supabaseService.client.auth.signUp({
            email: formValues.email,
            password: formValues.password,
            phone: formValues.phone,
            options: {
                data: {
                    username: formValues.username
                }
            }
        });
    }
}