<div class="container p-3 d-flex flex-column">
    <app-breadcrumbs *ngIf="breadcrumbs" [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
    <div class="pt-1" *ngIf="book">
        <div class="d-flex flex-row flex-wrap justify-content-between">
            <p class="display-6">{{ book.title }}</p>
            <div *ngIf="(user$ | async)?.isAdmin" class="d-flex flex-row">
                <button class="m-2 btn btn-primary d-none d-md-block" context="primary" routerLink="bewerken">Bewerken</button>
                <button class="m-2 btn btn-secondary d-none d-md-block" context="primary" routerLink="status-admin">Update status</button>
            </div>
        </div>
        <p class="lead" *ngIf="book.subtitle"><small>{{ book.subtitle }}</small></p>
        <p class="d-flex flex-column flex-lg-row">
            <span class="" *ngIf="book.authors">
                <span class="fw-bold">Auteur(s): </span>
                <span *ngFor="let author of book.authors; let first = first">
                    <span *ngIf="!first">, </span>{{ author.name }}
                </span>
            </span>
            <span class="d-none d-lg-flex px-2" *ngIf="publishers && publishers.length"> | </span>
            <span *ngIf="publishers && publishers.length">Uitgeverij: {{ publishers }}</span>
        </p>
        <div class="d-flex flex-column flex-lg-row pt-3 align-items-center align-items-lg-start">
            <div class="pe-4 pb-4">
                <div *ngIf="book.uuid" class="flex-item">
                    <div class="pb-4">
                        <img [src]="imageUrl">
                    </div>
                </div>
    
                <ui-button context="outline-success" [fluid]="true" title="Je kan dit boek lenen!" [disabled]="!isAvailable" [routerLink]="(user$ | async) ? ['aanvraag'] : ['/login']">
                    <span *ngIf="(user$ | async)">🟢 RESERVEER NU</span>
                    <span *ngIf="!(user$ | async)">🟢 Log in om te reserveren</span>
                    <span *ngIf="!isAvailable">🔴 Niet beschikbaar</span>
                </ui-button>
            </div>
            

            <div class="flex-item w-100 ps-4 book-description">
                <div class="pb-3 d-flex flex-column" *ngIf="book.description">
                    <label>Beschrijving</label>
                    <span [innerHtml]="book.description"></span>
                </div>
                <div class="pb-3 d-flex flex-column" *ngIf="book.isbn10">
                    <label>ISBN10</label>
                    <span>{{ book.isbn10 }}</span>
                </div>
                <div class="pb-3 d-flex flex-column" *ngIf="book.isbn13">
                    <label>ISBN13</label>
                    <span>{{ book.isbn13 }}</span>
                </div>
                <div class="pb-3 d-flex flex-column" *ngIf="tags && tags.length">
                    <label>Tag(s)</label>
                    <span>{{ tags }}</span>
                </div>
                <!-- <div class="pb-3 d-flex flex-column" *ngIf="book.languages">
                    <label>Taal</label>
                    <span>{{ book.languages }}</span>
                </div> -->
                <div class="pb-3 d-flex flex-column" *ngIf="book.publishedYear">
                    <label>Publicatiejaar</label>
                    <span>{{ book.publishedYear }}</span>
                </div>
                <div class="pb-3 d-flex flex-column" *ngIf="book.pageCount">
                    <label>Aantal blz</label>
                    <span>{{ book.pageCount }}</span>
                </div>
            </div>
            <div>
                <button class="btn btn-primary d-md-none" context="primary" *ngIf="(user$ | async)?.isAdmin" routerLink="editor">Bewerken</button>
            </div>
    </div>
</div>