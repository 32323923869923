import { Component, OnInit } from '@angular/core';
import { TextService } from 'src/app/services/text.service';

@Component({
    templateUrl: './about.component.html'
})
export class AboutComponent implements OnInit {
    public article?: {
        title: string;
        text: string;
    }
    constructor(
        private textService: TextService
    ) { }

    public async ngOnInit(): Promise<void> {
        this.article = await this.textService.getAboutText();
    }
}
