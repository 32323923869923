import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SupabaseService } from 'src/app/services/supabase.service';

interface Alert {
	type: string;
	message: string;
}

@Component({
    selector: 'app-reset-password',
    templateUrl: 'reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit {
    
    public alerts: Alert[] = [];

    public form = new FormGroup({
        password: new FormControl('', [Validators.required, Validators.minLength(5)]),
        passwordCheck: new FormControl('', [Validators.required])
    });
    public loading = false;

    constructor(
        private supabaseService: SupabaseService,
        private router: Router
    ) { }

    public ngOnInit() { }

    public async confirm(): Promise<any> {
        const password = this.form.get('password')?.value;
        const passwordCheck = this.form.get('passwordCheck')?.value;

        if (password != passwordCheck) {
            this.alerts.push({ type: 'danger', message: 'Je wachtwoorden komen niet overeen... Probeer ze opnieuw in te vullen.' });
        }
        if (!password) {
            throw new Error('No password found?');
        }

        const { data, error } = await this.supabaseService.client.auth.updateUser({ password });

        if (error) {
            this.alerts.push({ type: 'danger', message: 'Er liep iets fout... Je zult het mogelijk opnieuw moeten proberen. Onze excuses!' });
        } else {
            this.router.navigate(['../']);
        }
    }

    public close(alert: Alert): void {
        this.alerts.splice(this.alerts.indexOf(alert), 1);
    }
}
