<div class="container p-3 d-flex flex-column justify-content-center w-100 w-md-75 w-xl-50" *ngIf="!scannerIsRunning">
    <h1 class="pb-4 text-center">Boek toevoegen</h1>

    <div *ngIf="!book && !loading" class="container d-flex flex-column align-items-center justify-content-center my-3">
        <p class="text-center">
            Op dit moment werkt zoeken enkel via een correcte ISBN. 
        </p>
        <ui-button size="lg" (clicked)="startScanner()">Scan</ui-button>
        <ui-button size="sm" context="secondary" class="mt-1" (clicked)="showIsbnControl()">
            Toevoegen mbv ISBN
        </ui-button>
        <ui-button size="sm" context="secondary" class="mt-1" (clicked)="showAllControls()">
            Alles manueel ingeven 
        </ui-button>
    </div>
    
    <div class="container d-flex flex-column align-items-center justify-content-center my-3">
        <!-- <app-book-editor
            class="w-100"
            *ngIf="!loading && (book || formControls.length) || manualEnter"
            [fullForm]="form" [authorNameForm]="createAuthorForm" 
            [languageOptions]="languageOptions" [publisherOptions]="publisherOptions"
            (fullFormReset)="reset()" (search)="onSearch()" (submit)="submitBook($event)"
            [book]="book" [manualEnter]="manualEnter" [tagOptions]="tagOptions"
        ></app-book-editor> -->
    </div>

    <span class="text-danger text-center">
        {{ errorMessage }}
    </span>

    <div *ngIf="loading" class="container loader-container d-flex flex-column justify-content-center align-items-center">
        <img src="assets/loading.gif" class="d-flex flex-item loader">
        <span class="text-center">
            Even geduld, we halen data op vanuit OpenLibrary, Goodreads, Bol en Archive.Org...<br/>
            Dit duurt max. 20 seconden. Als het langer duurt, is er waarschijnlijk iets misgegaan,
            en kan je best Annuleren.
        </span>
        <ui-button class="mt-5" (clicked)="cancelSearch()">
            Annuleren
        </ui-button>
    </div>
</div>

<barcode-scanner-livestream
      (valueChanges)="onScanned($event)"
></barcode-scanner-livestream>
