import { Injectable } from '@angular/core';
import { SupabaseService } from './supabase.service';

@Injectable({
    providedIn: 'root'
})
export class EventsService   {
    constructor(private supabaseService: SupabaseService) {}

    public async getEvents(): Promise<any[]> {
        const result = await this.supabaseService.client.from('events')
            .select('*')
            .order('eventDate', { ascending: true });

        if (result.error || !result.data) {
            console.error(result.error);
            throw new Error('An error occured while fetching events.');
        }

        return result.data;
    }
}