import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LibraryService } from '../services/library.service';
import { Author } from '../types/supabase/supabase.models';

@Injectable({ providedIn: 'root' })
export class AuthorsResolver  {
  constructor(private libraryService: LibraryService) {}

  resolve = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Author[]> => {
    return await this.libraryService.getAuthors();
  }
}