<nav class="navbar navbar-expand-xl navbar-light bg-primary shadow">
  <div class="container">
    <a class="navbar-brand text-dark d-flex flex-row align-items-center me-5" routerLink="/">
      <img class="d-none d-sm-block shadow rounded-circle me-3" src="assets/logo.svg" width="55" height="55">
      <span>Project Madrigal</span>
    </a>

    <button type="button" class="navbar-toggler text-light bg-primary" (click)="isCollapsed = ! isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
      <ul class="navbar-nav me-5 mb-2 mb-lg-0">
        <li class="nav-item" *ngFor="let link of headerLinks">
          <a class="nav-link text-primary-light" [routerLink]="link.route" >
            {{ link.label }}
          </a>
        </li>
        <!-- <li *ngIf="adminLinks.length" class="nav-item dropdown">
          <a id="adminDropdown" class="nav-link text-primary-light dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">
            Admin
          </a>
          <ul class="dropdown-menu bg-primary" aria-labelledby="adminDropdown">
            <li *ngFor="let link of adminLinks">
              <a class="dropdown-item" [routerLink]="link.route">
                {{ link.label }}
              </a>
            </li>
          </ul>
        </li> -->
      </ul>
      <div class="navbar-nav d-flex flex-column flex-xl-row flex-grow-1 justify-content-between align-items-center">
        <form class="d-none d-lg-flex flex-grow-1 me-5 nav-item">
          <input 
                type="text" class="navbar-input form-control form-control-lg" placeholder="Zoek op ISBN / titel..." aria-label="Zoeken" aria-describedby="button-search"
                [(ngModel)]="searchValue" ngDefaultControl [ngModelOptions]="{standalone: true}" (keydown.enter)="onSearch()" (blur)="onSearch()" 
          >
        </form>
        <div class="navbar-nav">
          <div class="nav-item" *ngIf="(user$ | async) === undefined">
            <a class="nav-link text-primary-light" [routerLink]="['login']">
              <small>Inloggen / Registreren</small>
            </a>
          </div>
          <li *ngIf="(user$ | async) as user" class="nav-item" ngbDropdown>
            <a class="nav-link text-primary-light" tabindex="0" ngbDropdownToggle id="navbarDropdown2" role="button"> {{ user.username }} </a>
            <div ngbDropdownMenu aria-labelledby="navbarDropdown2" class="dropdown-menu dropdown-menu-end bg-primary">
              <a ngbDropdownItem href="#" (click)="$event.preventDefault();logout()">Uitloggen</a>
            </div>
          </li>
        </div>
      </div>
    </div>
  </div>
</nav>
