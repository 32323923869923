<div class="container py-3 px-0 px-lg-3 d-flex flex-row flex-wrap">
    <div class="w-100 row">
        <div class="col-12 col-xl-8">
            <section>
                <div class="col p-2" *ngIf="title">
                    <p class="display-6 mb-1 text-yellow-color" [innerHTML]="title">
                    </p>
                </div>
            </section>

            <section class="pt-4 px-2 d-flex d-lg-none">
                <div class="text-light w-100 rounded bg-primary shadow p-4 d-flex flex-column align-items-left">
                    <div class="">
                        <form class="">
                            <label for="book-search" class="mb-2">Zoek een boek in de bib van Project Madrigal:</label>
                            <div class="input-group">
                                <input id="book-search"
                                    type="text" class="form-control bg-primary-light text-primary" placeholder="Zoek op ISBN / titel..." aria-label="Zoeken" aria-describedby="button-search"
                                    [(ngModel)]="searchValue" ngDefaultControl [ngModelOptions]="{standalone: true}" (keydown.enter)="onSearch()"
                                >
                                <button class="btn input-btn bg-primary btn-outline-primary-light text-light" type="button" id="button-addon2" (click)="onSearch()">Zoek</button>
                            </div>
                          </form>
                    </div>   
                </div>
            </section>

            <section class="pt-4" *ngIf="introText">
                <h1 class="pb-2">
                    About
                </h1>
                <div class="col p-2 px-4">
                    <div class="text-dark rounded bg-purple-color shadow d-flex flex-column align-items-left">
                        <div class="p-4 text-dark">
                            <div class="pb-4">
                                <p class="lead mb-1">{{ introText.title }}</p>
                                <small>{{ introText.postAuthor }}, {{ introText.created_at | date:'short' }}</small>
                            </div>
                            <div>
                                <span [innerHTML]="introText.text"></span>
                            </div>
                        </div>
                     
                    </div>
                </div>
            </section>
        </div>
        <div class="col-12 col-xl-4">

            <section class="pt-4" *ngIf="events && events.length">
                <h1 class="pb-2">
                    Events
                </h1>

                <div class="pb-4 rounded text-purple-color" *ngFor="let event of events">
                    <div class="d-flex flex-row justify-content-start align-items-stretch">
                        <div class="calendar me-4 d-flex flex-column align-items-stretch">
                            <div class="d-flex flex-column calendar-header align-items-center w-100 small">{{ getDate(event.eventDate) | date:'MMM' }}</div>
                            <div class="d-flex flex-column calendar-body align-items-center w-100">{{ getDate(event.eventDate) | date:'dd' }}</div>
                        </div>
                        <div class="">
                            <span class="fw-bold fs-5">{{ event.title }}</span>
                            <div>
                                {{ event.location }}<br>
                                {{ event.eventTime }}<br>
                                <div class="description">
                                    <span [innerHTML]="event.description"></span>
                                </div>
                                <a [href]="event.link" class="fs-6" target="_blank">
                                    Meer informatie
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section *ngIf="spotlightBook">
                <h1 class="pb-2">
                    Aanrader
                </h1>
            
                <div class="row gx-1 px-4">
                    <div class="col p-2">
                        <div class="p-4 text-dark rounded bg-yellow-color shadow d-flex flex-column align-items-center">
                            <div class="cursor-pointer">
                                <img class="pb-3" *ngIf="spotlightBook.uuid" (click)="goToBook(spotlightBook.id)" [src]="getImageUrl(spotlightBook.uuid)">
                            </div>
                            
                            <p class="lead mb-1 cursor-pointer text-center" (click)="goToBook(spotlightBook.id)">
                                <strong>{{ spotlightBook.title }}</strong>
                            </p>
                            <p class="mb-2">{{ authorNames(spotlightBook.authors) }}</p>
                        </div>
                    </div>
                </div>
            </section>

            <section class="pt-4" *ngIf="latestBook">
                <h1 class="pb-2">
                    Nieuw
                </h1>
            
                <div class="row gx-1 px-4">
                    <div class="col p-2">
                        <div class="p-4 text-dark rounded bg-primary shadow d-flex flex-column align-items-center">
                            <div class="cursor-pointer">
                                <img class="pb-3" *ngIf="latestBook.uuid" (click)="goToBook(latestBook.id)" [src]="getImageUrl(latestBook.uuid)">
                            </div>
                            
                            <p class="lead mb-1 cursor-pointer text-center" (click)="goToBook(latestBook.id)">
                                <strong>{{ latestBook.title }}</strong>
                            </p>
                            <p class="mb-2">{{ authorNames(latestBook.authors) }}</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>
