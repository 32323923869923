
<nav  aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item">
            <ui-svg name="home" routerLink=""></ui-svg>
        </li>
        <li *ngFor="let breadcrumb of breadcrumbs; let i = index; let last = last" 
            class="breadcrumb-item" [class.active]="last" 
        >
            <a *ngIf="breadcrumb.routerLink" [routerLink]="breadcrumb.routerLink">
                <span>{{ breadcrumb.label }}</span>
            </a>
            <span *ngIf="!breadcrumb.routerLink">{{ breadcrumb.label }}</span>
        </li>
    </ol>
</nav>