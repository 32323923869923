import { Injectable } from '@angular/core';
import { SupabaseService } from './supabase.service';
import { BehaviorSubject } from 'rxjs';
import { Tag } from '../types/supabase/supabase.models';

@Injectable({
    providedIn: 'root'
})
export class TagsService {
    public allTags$: BehaviorSubject<Tag[]> = new BehaviorSubject<Tag[]>([]);

    constructor(private supabaseService: SupabaseService) {
    }

    public async getAllTags(): Promise<Tag[]> {
        const result = await this.supabaseService.client.from('tags').select().eq('showInFrontend', true);
        return (result.data ?? []) as Tag[];
    }

    public async saveTagName(id: number, name: string): Promise<any> {
        const existingTag = await this.supabaseService.client.from('Tag').select('id').eq('name', name).maybeSingle();
        if (existingTag.data) {
            await this.saveReferenceTo(id, existingTag.data.id);
        } else {
            await this.supabaseService.client.from('Tag').update({ name }).eq('id', id);
        }
    }

    public async saveIsMainTag(id: number, mainTag: boolean): Promise<any> {
        await this.supabaseService.client.from('Tag').update({ mainTag }).eq('id', id);
    }

    public async saveShowTag(id: number, show: boolean): Promise<any> {
        await this.supabaseService.client.from('Tag').update({ show }).eq('id', id);
    }

    public async saveReferenceTo(id: number, referenceToId: number): Promise<any> {
        await this.supabaseService.client.from('Tag').update({ referenceToId }).eq('id', id);

        const result = await this.supabaseService.client.from('_BookToTag').select('bookId').eq('tagId', id);

        if (result.error || !result.data) {
            throw new Error(result.error.message);
        }

        for (let data of result.data) {
            const bookId = data.bookId;
            await this.supabaseService.client.from('_BookToTag').delete().eq('bookId', bookId).eq('tagId', id).single();
            await this.supabaseService.client.from('_BookToTag').insert({ bookId, tagId: referenceToId });
        }

        await this.deleteTag(id);
    }

    public async deleteTag(tagId: number): Promise<any> {
        await this.supabaseService.client.from('Tag').update({ softDeleted: true }).eq('id', tagId);
    }
    
    public async reinstateTag(tagId: number): Promise<any> {
        await this.supabaseService.client.from('Tag').update({ softDeleted: false }).eq('id', tagId);
    }
}