import { Component, OnInit } from '@angular/core';
import { filter, firstValueFrom } from 'rxjs';
import { SessionService } from '../services/session.service';

@Component({
    templateUrl: './client.layout.html'
})
export class ClientLayoutComponent implements OnInit {
    public routes = [
        { label: 'Home', route: '/' },
        { label: 'Boeken', route: ['/boeken'] },
        { label: 'Praktisch', route: ['/praktisch'] },
        { label: 'Over ons', route: ['/over-project-madrigal'] }
    ];
    public adminRoutes: { label: string, route: string[] }[] = [
        
    ];

    constructor(
        private sessionService: SessionService
    ) {}
    
    public async ngOnInit(): Promise< void > {
        const user = await firstValueFrom(this.sessionService.user$.pipe(filter(user => !!user)));
        if (user?.isAdmin) {
            this.adminRoutes = [
                { label: 'Scanner', route: ['/admin/scanner'] },
            ];
        }
    }
}
