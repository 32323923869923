import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { SupabaseService } from 'src/app/services/supabase.service';
import { environment } from 'src/environments/environment';

interface Alert {
	type: string;
	message: string;
}

@Component({
    selector: 'app-forgot-password',
    templateUrl: 'forgot-password.component.html'
})
export class ForgotPasswordComponent implements OnInit {
    
    public alerts: Alert[] = [];
    public form = new FormGroup({
        email: new FormControl('')
    });
    public loading = false;

    constructor(
        private supabaseService: SupabaseService
    ) { }

    public ngOnInit() { }

    public async confirm(): Promise<any> {
        // const { data, error } = await this.supabaseService.client.auth.updateUser({
        //     password: this.form.get('email')?.value ?? ''
        // });
        const email = this.form.get('email')?.value ?? '';
        const { data, error } = await this.supabaseService.client.auth.resetPasswordForEmail(email, {
            redirectTo: environment.redirectRoute,
        });
       
        if (error) {
            this.alerts.push({ type: 'danger', message: 'Oeps, er ging iets mis. Probeer het later nog eens of neem contact met ons op.' });
        } else {
            this.alerts.push({ type: 'success', message: 'Kijk je mailbox na (eventueel in de spam folder) voor verdere instructies.' });
        }
    }

    public close(alert: Alert): void {
        this.alerts.splice(this.alerts.indexOf(alert), 1);
    }
}
