import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BarcodeScannerLivestreamComponent } from 'ngx-barcode-scanner';
import { QuaggaJSResultObject } from '@ericblade/quagga2';
import { LibraryService } from 'src/app/services/library.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Book, Publisher, Tag } from 'src/app/types/supabase/supabase.models';

@Component({
    selector: 'app-add-books',
    templateUrl: './add-books.component.html',
    styleUrls: ['./add-books.component.scss']
})
export class AddBooksComponent implements OnInit {

    @ViewChild(BarcodeScannerLivestreamComponent) barcodeScanner!: BarcodeScannerLivestreamComponent;

    public form: FormGroup = new FormGroup({});
    public createAuthorForm: FormGroup = new FormGroup({ firstName: new FormControl(), lastName: new FormControl() })

    public book?: Book;

    public loading: boolean = false;
    public barcode: string = '';
    public scannerIsRunning: boolean = false;
    public errorMessage: string | null = null;

    private fetchSubscription?: Subscription;

    // public languageOptions?: Language[];
    public publisherOptions?: Publisher[] = [];
    public tagOptions: Tag[] = []; 

    public manualEnter: boolean = false;

    public get formControls() {
        return Object.keys(this.form.controls);
    }

    constructor(
        private libraryService: LibraryService,
        private http: HttpClient,
        private route: ActivatedRoute
    ) { }

    public ngOnInit(): void {
        this.tagOptions = this.route.snapshot.data['tags'];

        this.libraryService.getPublishers().then(response => {
            this.publisherOptions = response;
        });
    }

    public startScanner(): void {
        this.loading = true;
        this.scannerIsRunning = true;
        this.barcodeScanner.start();
    }

    public onScanned(result: QuaggaJSResultObject) {
        this.barcode = result.codeResult.code ?? 'ERROR';
        if (this.barcode) {
            this.fetchBook(this.barcode);
            this.barcodeScanner.stop();
            this.scannerIsRunning = false;
        }
    }

    public showIsbnControl(): void {
        this.errorMessage = '';
        this.form.addControl('ISBN', new FormControl(''));
    }

    public showAllControls(): void {
        this.errorMessage = '';
        this.manualEnter = true;
    }

    public onSearch(): void {
        const value = this.form.controls['ISBN'].value;
        this.fetchBook(value);
    }

    public cancelSearch(): void {
        this.fetchSubscription?.unsubscribe();
        this.loading = false;
    }

    public fetchBook(value?: string): void {
        if (value && [10,13].includes(value.length)) {
            this.loading = true;
            this.reset();
        }
        else {
            console.log('Invalid ISBN length');
            this.errorMessage = `Het ISBN-nummer moet ofwel 10 of 13 karakters lang zijn`;
            this.loading = false;
        }
    }

    public async submitBook(submittedValues: any): Promise<void> {
        this.errorMessage = '';

        let image: Blob | undefined = undefined;
        if (submittedValues.chosenImage) {
            image = await firstValueFrom(this.http.get(submittedValues.chosenImage, { responseType: 'blob' }));
        }

        const publishedValue = this.form.controls['Jaar'].value;
        const publishedYear = publishedValue ? parseInt(publishedValue) : undefined;

        const pageCountValue = this.form.controls['Aantal blz'].value;
        const pageCount = pageCountValue ? parseInt(pageCountValue) : undefined;

        // const selectedPublisher = this.publisherOptions?.filter(option => option.name === this.form.controls['Uitgeverij'].value);
        // const publisher = selectedPublisher?.length ? selectedPublisher[0] : new Publisher({ name: this.form.controls['Uitgeverij'].value });

        // const book: AddBookRequest = {
        //     isbn: this.form.controls['ISBN'].value,
        //     authors: submittedValues.authors,
        //     title: this.form.controls['Titel'].value,
        //     subtitle: this.form.controls['Subtitel'].value,
        //     publishedYear,
        //     publisher,
        //     pageCount,
        //     languages,
        //     description: this.form.controls['Beschrijving'].value,
        //     tags: submittedValues.tags
        // };

        // this.libraryService.addToLibrary(book).then(async (response: any) => {
        //     if (image) {
        //         await this.libraryService.addImage(response.addedBook.id, image);
        //     }
        //     this.reset();
        // });
    }

    public setErrorMessage(message: string): void {
        this.errorMessage = message;
    }

    public reset(): void {
        this.errorMessage = '';
        this.manualEnter = false;
        this.form = new FormGroup({});
        this.createAuthorForm.get('firstName')?.reset();
        this.createAuthorForm.get('lastName')?.reset();
        this.book = undefined;
    }
}
