import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
    selector: 'ui-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit, OnChanges {
    @Input() context: string = 'primary';
    @Input() size: 'sm'|'md'|'lg' = 'md';
    @Input() fluid: boolean = false;
    @Input() loading: boolean = false;
    @Input() disabled: boolean = false;
    @Input() active: boolean = false;

    public styles: any = {};

    @Output() clicked: EventEmitter<Event> = new EventEmitter();

    constructor(
    ) { }

    public ngOnChanges(changes: SimpleChanges): void {
        this.setStyles();
    }

    public ngOnInit(): void { 
    }

    public onClick(event: Event): void {
        this.clicked.emit(event);
    }

    private setStyles = () => {
        this.styles = { 
        }
    }
}
