import { Component, OnDestroy, OnInit } from '@angular/core';
import { SearchService } from 'src/app/services/search.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-books',
    templateUrl: './books.component.html',
    styleUrls: [
        './books.component.scss'
    ]
})
export class BooksComponent implements OnInit, OnDestroy {

    public filter: any = {};

    public loading$: Observable<boolean> = this.searchService.loading$;

    public breadcrumbs = [
        { label: 'Boeken' }
    ];

    constructor(
        private searchService: SearchService
    ) { }

    public async ngOnInit(): Promise<void> {
    }

    public searchAllValues(): void {
        
    }

    public ngOnDestroy(): void {
        this.searchService.reset();
    }
}
