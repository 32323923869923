<div *ngIf="!(loading$ | async) && books">
    <ng-container *ngIf="!books.length; else bookList">
        <p>Geen boeken gevonden met deze filter... 🫠</p>
    </ng-container>
</div>

<ng-template #bookList>
    <div *ngFor="let book of books">
        <div class="d-flex flex-row flex-wrap flex-md-nowrap book-row">
            <div class="d-flex flex-column mb-3 mb-md-1 image-box w-100 w-md-25">
                <a class="align-self-center align-self-lg-start cursor-pointer vh-50 w-50 w-md-100" href="#" [routerLink]="['id', book.id]">
                    <img *ngIf="book.uuid" class="w-100" [src]="getImageUrl(book.uuid)">
                </a>
            </div>
            
            <div class="book-description ps0 ps-md-5 pt-3 d-flex flex-column justify-content-between">
                <div class="d-flex flex-column">
                    <a class="lead mb-0" href="#" [routerLink]="['id', book.id]">{{ book.title }}</a>
                    <p *ngIf="book.subtitle" class="small cursor-pointer" [routerLink]="['id', book.id]">{{ book.subtitle }}</p>
                    <p>
                        <span *ngFor="let author of book.authors">
                            {{ author.name }}
                        </span>
                        <span *ngIf="book.publishers && book.publishers.length">| {{ getPublishers(book.publishers) }}</span>
                    </p>
                    <p class="small text-purple-color text-truncate-4">{{ book.description }}</p>
                </div>
    
                <div class="w-100 d-flex flex-column flex-lg-row align-items-center align-items-lg-end justify-content-between">
                    <div class="d-flex flex-row flex-wrap w-100 w-md-75 pb-3 pb-md-0" *ngIf="getTags(book) as tags">
                        <ui-tag 
                            *ngFor="let tag of tags; let i = index" [action]="true" 
                            [class.me-1]="i < 3 || book.showAllTags" [class.mb-1]="i < 3 || book.showAllTags" color="yellow-color" textColor="dark"
                        >
                            <span *ngIf="i < 3 || book.showAllTags">{{ tag.displayName }}</span>
                        </ui-tag>
                        <span *ngIf="!book.showAllTags && tags && tags.length > 3" class="cursor-pointer" (click)="showAllTags(book)">...</span>
                    </div>
                    <ui-button context="outline-success" title="Je kan dit boek lenen!" *ngIf="isAvailable(book)" [routerLink]="['id', book.id, 'aanvraag']">
                        🟢 Beschikbaar
                    </ui-button>
                    <div class="cursor-pointer py-2" *ngIf="!isAvailable(book)">
                        <small title="Laat me weten wanneer het beschikbaar is (coming soon)">🔴 Niet beschikbaar</small>
                    </div>
                </div>
            </div>
        </div>
        <hr class="my-4">
    </div>
</ng-template>

<ng-container *ngIf="(currentPage$ | async) as currentPage">
    <div *ngIf="pagesToDisplay" class=" w-100 w-md-50 mx-auto mt-5 text-center fs-6 text-purple-color">
        <span *ngFor="let page of pagesToDisplay" class="me-4" >
            <span *ngIf="page === '...'">...</span>
            <span class="cursor-pointer link" *ngIf="page !== '...'" [class.currentPage]="isCurrentPage(currentPage, page)" (click)="setPage(page)">{{ page }}</span>
        </span>
    </div>
</ng-container>