import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LibraryService } from '../services/library.service';
import { Book } from '../types/supabase/supabase.models';

@Injectable({ providedIn: 'root' })
export class BookResolver  {
  constructor(private libraryService: LibraryService) {}

  resolve = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Book | null> => {
    if (!route.paramMap.get('id')) {
        throw new Error('No book id provided.')
    }
    const bookId = parseInt(<string>route.paramMap.get('id'));
    return await this.libraryService.getBook(bookId);
  }
}