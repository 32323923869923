import { Router } from '@angular/router';
import { Observable, lastValueFrom } from 'rxjs';
import { SessionService } from 'src/app/services/session.service';
import { SearchService } from 'src/app/services/search.service';
import { UserInfo } from 'src/app/types/user/session-info.type';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    public searchValue: string | undefined = '';
    public isCollapsed = true;
    
    public user$: Observable<UserInfo | null | undefined> = this.sessionService.user$;

    @Input() headerLinks: { label: string, route: string|string[] }[] = [];
    @Input() adminLinks: { label: string, route: string|string[] }[] = [];

    constructor(
        private searchService: SearchService,
        private sessionService: SessionService,
        private router: Router
    ) { }

    public async ngOnInit(): Promise<any> {
        const activeFilters = await lastValueFrom(this.searchService.activeFilters$);
        this.searchValue = activeFilters.searchValue;
    }

    public onSearch(): void {
        this.searchService.searchText(this.searchValue, true);
    }

    public logout(): void {
        this.sessionService.logout().then(() => location.reload());
    }
}
