import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { ConfigurationService } from 'src/app/services/config.service';
import { LibraryService } from 'src/app/services/library.service';

@Component({
    selector: 'app-status',
    templateUrl: './status.component.html'
})
export class StatusComponent implements OnInit {
    public form: FormGroup = this.formBuilder.group({
        bookid: new FormControl(1)
    })
    public response: unknown = {};
    public imgUrl: string = '';

    constructor(
        private formBuilder: FormBuilder,
        private libraryService: LibraryService
    ) { }

    ngOnInit(): void { }

    public getBook(): void {
        const id = this.form.controls['bookid'].value;
        this.libraryService.getBook(id).then((res: any) => {
            const urlCreator = window.URL || window.webkitURL;

            const book = res.book?.image?.data;
            const imgBlob = new Blob(book);
            if (imgBlob)
            {
                this.imgUrl = urlCreator.createObjectURL(imgBlob);
            }

            res.book = 'See if image renders below';
            this.response = res;
        });
    }
}
