import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, lastValueFrom, map, firstValueFrom } from 'rxjs';
import { LibraryService } from './library.service';
import { Router } from '@angular/router';
import { Publisher, Tag } from '../types/supabase/supabase.models';

@Injectable({
    providedIn: 'root'
})
export class SearchService {
    public activeFilters$: BehaviorSubject<any> = new BehaviorSubject<any>({ page: 1 });
    public books$: BehaviorSubject<any> = new BehaviorSubject<any>({});
    public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    public activeTags$: Observable<Tag[]> = this.activeFilters$.pipe(map(filter => filter.tags));
    // public activeLanguages$: Observable<Language[]> = this.activeFilters$.pipe(map(filter => filter.languages));
    public activePublishers$: Observable<Publisher[]> = this.activeFilters$.pipe(map(filter => filter.publishers));

    constructor(
        private libraryService: LibraryService,
        private router: Router
    ) { }

    public reset(): void {
        const emptyFilter = {
            page: 1
        }
        this.activeFilters$.next(emptyFilter);
        // this.fetchBooks(emptyFilter).then(() => this.loading$.next(false));
    }

    public async filter(filter: any, goToBooksPage?: boolean): Promise<void> {
        this.loading$.next(true);
        const nextFilter = await this.getFilter(filter);
        await this.fetchBooks(nextFilter);
        this.setQueryParams(nextFilter, goToBooksPage);
        this.activeFilters$.next(nextFilter);
        this.loading$.next(false);
    }

    public async searchText(searchValue?: string, goToBooksPage?: boolean): Promise<void> {
        if (!searchValue) {
            this.reset();
        }
        await this.filter({ searchValue, page: 1 }, goToBooksPage);
    }

    private async getFilter(filter: Partial<any>): Promise<any> {
        const currentFilter = await firstValueFrom(this.activeFilters$);
        return { ...currentFilter, ...filter };
    }

    private async fetchBooks(filter: any): Promise<void> {
        const books = await this.libraryService.getBooks(filter);
        this.books$.next(books);
    }

    private setQueryParams(filter: any, goToBooksPage?: boolean): void {
        const queryParams = {
            page: filter.page ?? 1,
            // languages: filter.getLanguageNames(),
            tags: filter.tagIds?.join(','),
            // publishers: filter.getPublisherNames(),
            searchValue: filter.searchValue
        };
        const navigateTo = goToBooksPage ? ['boeken'] : [];
        this.router.navigate(navigateTo, { queryParams, queryParamsHandling: 'merge' });
    }
}