import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'ui-tag',
    templateUrl: './tag.component.html',
    styleUrls: ['./tag.component.scss']
})
export class TagComponent implements OnInit {
    @Input() color?: string;
    @Input() textColor?: string = 'light';
    @Input() action: boolean = false;
    @Input() active: boolean = false;

    constructor(
    ) { }

    ngOnInit(): void { }
}
