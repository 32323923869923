import { BehaviorSubject } from 'rxjs';
import { SupabaseService } from './supabase.service';
import { UserInfo } from '../types/user/session-info.type';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    public user$: BehaviorSubject<UserInfo|null|undefined> = new BehaviorSubject<UserInfo|null|undefined>(undefined);

    constructor(
        private supabaseService: SupabaseService
    ) {}

    public async getUser(): Promise<void> {
        const user = await this.supabaseService.getSession();
        this.user$.next(user ?? undefined);
    }

    public async login(loginData: any): Promise<void> {
        const user = await this.supabaseService.signInWithPassword(loginData.email, loginData.password);
        this.user$.next(user);
    }

    public async logout(): Promise<void> {
        await this.supabaseService.client.auth.signOut();
        this.user$.next(undefined);
        localStorage.clear();
    }
}