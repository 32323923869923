import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { IsAuthorizedGuard } from './guards/authorized.guard';
import { ClientLayoutComponent } from './layouts/client.layout';
import { MainComponent } from './main.component';
import { AddBooksComponent } from './pages/admin/add-books/add-books.component';
import { StatusComponent } from './pages/admin/status/status.component';
import { AboutComponent } from './pages/client/about/about.component';
import { BookDetailEditorComponent } from './pages/client/book-detail-edit/book-detail-edit.component';
import { BookDetailComponent } from './pages/client/book-detail/book-detail.component';
import { BookRequestAdminComponent } from './pages/client/book-request-admin/book-request.admin.component';
import { BookRequestComponent } from './pages/client/book-request/book-request.component';
import { BooksComponent } from './pages/client/books/books.component';
import { HomeComponent } from './pages/client/home/home.component';
import { PracticalInfoComponent } from './pages/client/practical-info/practical-info.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { AuthorsResolver } from './resolvers/authors.resolver';
import { BookResolver } from './resolvers/book.resolver';
import { PublishersResolver } from './resolvers/publishers.resolver';
import { TagsResolver } from './resolvers/tags.resolver';


const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: 'admin',
        component: ClientLayoutComponent,
        canActivate: [IsAuthorizedGuard],
        children: [
          {
            path: '',
            redirectTo: 'scanner',
            pathMatch: 'full'
          },
          {
            path: 'status',
            component: StatusComponent
          },
          {
            path: 'scanner',
            component: AddBooksComponent
          }
        ]
      },
      {
        path: '',
        component: ClientLayoutComponent,
        children: [
          {
            path: '',
            component: HomeComponent,
            pathMatch: 'full'
          },
          {
            path: 'praktisch',
            component: PracticalInfoComponent
          },
          {
            path: 'over-project-madrigal',
            component: AboutComponent
          },
          {
            path: 'boeken',
            children: [
              {
                path: '',
                pathMatch: 'full',
                component: BooksComponent
              },
              {
                path: 'id/:id',
                children: [
                  {
                    path: '',
                    pathMatch: 'full',
                    component: BookDetailComponent,
                    resolve: {
                      book: BookResolver
                    }
                  },
                  {
                    path: 'bewerken',
                    pathMatch: 'full',
                    component: BookDetailEditorComponent,
                    resolve: {
                      book: BookResolver,
                      tags: TagsResolver,
                      authors: AuthorsResolver
                    }
                  },
                  {
                    path: 'aanvraag',
                    pathMatch: 'full',
                    component: BookRequestComponent,
                    resolve: {
                      book: BookResolver
                    }
                  },
                  {
                    path: 'status-admin',
                    pathMatch: 'full',
                    component: BookRequestAdminComponent,
                    resolve: {
                      book: BookResolver
                    }
                  }
                ]
              }
            ]
          },
          {
            path: 'login',
            component: LoginComponent
          },
          {
            path: 'forgot-password',
            component: ForgotPasswordComponent
          },
          {
            path: 'reset-password',
            component: ResetPasswordComponent
          },
          {
            path: 'register',
            component: RegisterComponent
          }
        ]
      },
    ]
  }  
];

export const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routingConfiguration)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
