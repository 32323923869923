import { Injectable } from '@angular/core';
import { createClient, SupabaseClient } from '@supabase/supabase-js';
import { UserInfo } from '../types/user/session-info.type';

@Injectable({ providedIn: 'root' })
export class SupabaseService  {
    private supabaseClient?: SupabaseClient;
    public get client(): SupabaseClient {
        if (this.supabaseClient) {
            return this.supabaseClient;
        }

        const supabase = createClient(
            'https://uuxamqpddbrazazfzdqa.supabase.co',
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InV1eGFtcXBkZGJyYXphemZ6ZHFhIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTQzNTExMzIsImV4cCI6MjAwOTkyNzEzMn0.s4L8DZu7IUr_CCzkZfG8dTXjO6g9bVT85znggQnwe9k'
        );
        this.supabaseClient = supabase;

        return this.supabaseClient;
    }

    public async getBook(id: number): Promise<any> {
        const select = '*, _AuthorToBook(*, Author(*)), Copy(*), _BookToTag(*, Tag(*)), _BookToLanguage(*, Language(*)), Publisher(*)';
        const result = await this.client
                                 .from('books')
                                 .select(select)
                                 .eq('id', id)
                                 .maybeSingle();

        const book = result.data as any;

        if (!book) {
            console.log('Woops, no book.');
            return undefined;
        }

        return {
            id: book.id,
            isbn: book.isbn,
            authors: book._AuthorToBook.map((ab: any) => ab.Author),
            title: book.title,
            subtitle: book.subtitle,
            description: book.description,
            publishedYear: book.publishedYear,
            publisher: book.Publisher?.name,
            pageCount: book.pageCount,
            languages: book._BookToLanguage.map((bl: any) => bl.Language),
            tags: book._BookToTag.map((bt: any) => bt.Tag),
            image: book.image,
            copies: book.Copy.map((copy: any) => copy.status)
        };
    }
    public async getSession(): Promise<UserInfo | null> {
        const { data, error } = await this.client.auth.getSession();
        if (error || !data.session?.user) {
            return null;
        }
        const returnedDetails = data.session.user;
        const userInfo: UserInfo = {
            email: returnedDetails.email as string,
            username: returnedDetails.user_metadata['username'],
            profilePicture: returnedDetails.user_metadata['profilePicture'],
            isAdmin: returnedDetails.role === 'service_role'
        };
        return userInfo;
    }

    public async signInWithPassword(email: string, password: string): Promise<UserInfo | null> {        
        const result = await this.client.auth.signInWithPassword({ email, password });
        if (result.error || !result.data) {
            return null;
        }
        const returnedDetails = result.data.session?.user;
        if (!returnedDetails) {
            return null;
        }
        const userInfo: UserInfo = {
            email: returnedDetails.email as string,
            username: returnedDetails.user_metadata['username'],
            profilePicture: returnedDetails.user_metadata['profilePicture'],
            isAdmin: returnedDetails.user_metadata['isAdmin']
        };
        return userInfo;
    }

    public async updateUser(valueToUpdate: Partial<UserInfo>): Promise<any> {
        const result = await this.client.auth.updateUser({ data: valueToUpdate });
        console.log(result);
        return result;
    }

    public async uploadImage(fileName: string, file: File, bucket: string): Promise<boolean> {
        const { data, error } = await this.client.storage.from(bucket)
            .upload(fileName, file);

        return !error;
    }
}