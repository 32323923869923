import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { LibraryService } from 'src/app/services/library.service';
import { SearchService } from 'src/app/services/search.service';
import { TagsService } from 'src/app/services/tags.service';
import { Publisher, Tag } from 'src/app/types/supabase/supabase.models';

@Component({
    selector: 'app-books-filter',
    templateUrl: './books-filter.component.html',
    styleUrls: ['./books-filter.component.scss']
})
export class BooksFilterComponent implements OnInit, OnDestroy {

    public allTags!: Tag[];
    // @Input() public allLanguages!: Language[];
    public allPublishers!: Publisher[];

    public filter: any = {};
    public selectedTags: { id: number }[] = [];
    public selectedPublishers: { id: number }[] = [];
    public filterCollapsed: boolean = true;

    constructor(
        private tagsService: TagsService,
        private libraryService: LibraryService,
        private searchService: SearchService
    ) {}

    public async ngOnInit(): Promise<void> {
        this.allTags = await this.tagsService.getAllTags();
        this.allPublishers = await this.libraryService.getPublishers();
        this.searchService.activeFilters$.subscribe(filter => this.filter = filter);
    }

    public onSearch(): void {
        this.searchService.filter({
            tagIds: this.selectedTags?.map(tag => tag.id) ?? null,
            publisherIds: this.selectedPublishers?.map(publisher => publisher.id) ?? null,
            page: 1
        });
    }

    public ngOnDestroy(): void {
        this.searchService.reset();
    }
}
