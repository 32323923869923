<div class="container" *ngIf="article">
    <h1 class="pb-3 d-flex flex-column align-items-center">
        {{ article.title }}
    </h1>
    <div class="row">
        <div class="text-dark rounded bg-purple-color shadow d-flex flex-column align-items-left p-4 col-12 col-md-8 offset-md-2">
            <p [innerHTML]="article.text"></p>
        </div>
    </div>
</div>
