<form class="d-flex d-md-none">
        <input id="book-search"
            type="text" class="form-control bg-purple-darker text-purple-light bg-outline-purple-light form-control-lg" 
            placeholder="Zoek op ISBN / titel..." aria-label="Zoeken" aria-describedby="button-search" [(ngModel)]="filter.searchValue"
            (keydown.enter)="onSearch()" name="search"
        >
</form>


<form class="collapse d-md-block pt-3" [class.d-block]="filter.tags?.length || filter.publishers?.length" id="filterCollapse">
    <p class="brand-font d-none d-md-block">Categorieën</p>
    <ng-select class="mt-1 mb-2" name="tags" [items]="allTags" [(ngModel)]="selectedTags" placeholder="Categorieën" bindLabel="displayName" [multiple]="true">
    </ng-select>

    <!-- <p class="brand-font d-none d-md-block">Taal</p>
    <ng-select class="mt-1 mb-2" name="languages" [items]="allLanguages" [(ngModel)]="filter.languages" placeholder="Taal" bindLabel="name" [multiple]="true">
    </ng-select> -->

    <p class="brand-font d-none pt-3 d-md-block">Uitgeverij</p>
    <ng-select class="mt-1 mb-2" name="publishers" [items]="allPublishers" [(ngModel)]="selectedPublishers" placeholder="Uitgeverij" bindLabel="name" [multiple]="true">
    </ng-select>
</form>

<div class="pt-3 d-flex flex-column justify-content-center align-items-center">
    <button class="btn input-btn btn-primary b-none text-dark w-50" type="button" id="button-addon2" (click)="onSearch()">Zoek</button>
    <button class="d-block d-md-none btn bg-primary mt-1 w-50"  data-bs-toggle="collapse" data-bs-target="#filterCollapse" 
            [disabled]="filter.tags?.length || filter.publishers?.length"
            aria-controls="filterCollapse" aria-expanded="false" aria-label="Toggle filter" (click)="filterCollapsed = !filterCollapsed">
        {{ !filterCollapsed || (filter.tags?.length || filter.publishers?.length) ? 'Verberg filter' : 'Toon filter' }}
    </button>
</div>