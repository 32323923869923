import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigurationService } from 'src/app/services/config.service';
import { LibraryService } from 'src/app/services/library.service';
import { SupabaseService } from 'src/app/services/supabase.service';
import { Book, Publisher } from 'src/app/types/supabase/supabase.models';

interface Alert {
	type: string;
	message: string;
}

@Component({
    selector: 'app-book-request',
    templateUrl: './book-request.component.html'
})
export class BookRequestComponent implements OnInit {
    public book!: Book;
    public imageUrl: string = '';
    public breadcrumbs!: any[]
    public bookRequestText: string = '';
    public alerts: Alert[] = [];

    public form: FormGroup = new FormGroup({
        availability: new FormControl(''),
        comments: new FormControl('')
    });
    
    // public languageOptions?: Language[];
    public publisherOptions?: Publisher[];
    public errorMessage: string | null = null;

    public showSubmit: boolean = true;

    private get bookTitle() {
      return this.book?.title;
    }
    
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private libraryService: LibraryService,
        private configurationService: ConfigurationService,
        private supabaseService: SupabaseService
    ) {}

    public async ngOnInit(): Promise<void> {
        this.book = this.route.snapshot.data['book'];
        this.breadcrumbs = [
            { label: 'Boeken', routerLink: ['../../../'] },
            { label: this.bookTitle, routerLink: ['../'] },
            { label: 'Reserveren' }
        ];

        this.bookRequestText = await this.configurationService.getBookRequestText();
    }

    public async requestBook(): Promise<void> {
        const { data, error } = await this.supabaseService.client.auth.getSession();
        const user = data.session?.user;
        const token = data.session?.access_token;

        if (!user || !token) {
            this.alerts.push({ type: 'danger', message: 'Er liep iets fout - probeer je even opnieuw?' });
            return;
        }

        const username = user.user_metadata['username'];
        this.libraryService.requestBook(user.id, this.book.copies[0].id, token, {
            user: {
                address: user.email,
                phone: user.phone,
                username
            },
            book: {
                isbn: this.book.isbn13,
                id: this.book.id,
                title: this.book.title,
                authors: this.book.authors.map(author => author.name)
            },
            comments: {
                availability: this.form.get('availability')?.value,
                comments: this.form.get('comments')?.value
            }
        }).then((response) => {
            this.showSubmit = false;
            this.alerts.push({ type: 'success', message: 'We contacteren je zo snel mogelijk op het emailadres dat je hebt opgegeven tijdens de registratie.' });
        });
    }

    public close(alert: Alert): void {
        this.alerts.splice(this.alerts.indexOf(alert), 1);
    }
}
