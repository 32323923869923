<div class="container p-3">
    <app-breadcrumbs *ngIf="breadcrumbs" [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
    <h1>Wijzig Boek</h1>

    <p>
        <span>Boek: {{ book.title }}</span>
    </p>

    <!-- <div *ngFor="let copy of book.copies"> -->
        <div class="form-group py-3 w-md-25">
            <p>Kopie ID:</p>
            <ng-select class="mt-1 mb-2" name="status" [items]="book.copies" [(ngModel)]="selectedBook" bindLabel="id" placeholder="Status"></ng-select>
            <ng-container *ngIf="selectedBook">
                <p>Notities: {{ selectedBook.comment }}</p> 
                <label for="authors">Status:</label>
                <ng-select class="mt-1 mb-2" name="status" [items]="statusOptions" [(ngModel)]="selectedBook.state" bindLabel="label" bindValue="id" placeholder="Status"></ng-select>
            </ng-container>
        </div>

    <form [formGroup]="reservationForm" *ngIf="" class="w-100 w-md-50">
        <div class="form-group">
            <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="existing-user" formControlName="existingUser">
                <label class="form-check-label" for="existing-user">
                    <span *ngIf="reservationForm.controls['existingUser'].value === true">Bestaande gebruiker</span>
                    <span *ngIf="reservationForm.controls['existingUser'].value === false">Geen gebruiker</span>
                </label>
            </div>
        </div>
        <div class="form-group">
            <div class="form-check form-switch" *ngIf="reservationForm.controls['existingUser'].value === false">
                <input class="form-check-input" type="checkbox" id="existing-user" formControlName="wantsToRegister">
                <label class="form-check-label" for="wantsToRegister">
                    <span *ngIf="reservationForm.controls['wantsToRegister'].value === true">Wilt een account</span>
                    <span *ngIf="reservationForm.controls['wantsToRegister'].value === false">Wilt geen account</span>
                </label>
            </div>
        </div>
        <div class="form-group w-md-50">
            <label for="username" class="form-label mt-3">Naam</label>
            <input type="text" class="form-control" formControlName="name">
        </div>
        <ng-container *ngIf="reservationForm.controls['existingUser'].value === false">
            <div class="form-group w-md-50">
                <label for="username" class="form-label mt-3">Email</label>
                <input type="text" class="form-control" formControlName="email">
            </div>
            <div class="form-group w-md-50">
                <label for="username" class="form-label mt-3">Telefoon</label>
                <input type="text" class="form-control" formControlName="phone">
            </div>
        </ng-container>
    </form>
</div>