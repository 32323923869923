// Angular Modules
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Extra modules
import { BarcodeScannerLivestreamModule } from 'ngx-barcode-scanner';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

// Layouts
import { ClientLayoutComponent } from './layouts/client.layout';

// Components
import { AppComponent } from './app.component';
import { MainComponent } from './main.component';
import { ButtonComponent } from './shared/ui/button/button.component';
import { HeaderComponent as HeaderUiComponent } from './shared/ui/header/header.component';
import { TagComponent } from './shared/ui/tag/tag.component';
import { SvgComponent } from './shared/ui/svg/svg.component';
import { HeaderComponent } from './shared/header/header.component';
import { BreadcrumbsComponent } from './shared/breadcrumbs/breadcrumbs.component';
import { BooksFilterComponent } from './pages/client/books/books-filter/books-filter.component';
import { BooksListComponent } from './pages/client/books/books-list/books-list.component';

// Components: Pages
import { HomeComponent } from './pages/client/home/home.component';
import { BooksComponent } from './pages/client/books/books.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { BookRequestComponent } from './pages/client/book-request/book-request.component';
import { AddBooksComponent } from './pages/admin/add-books/add-books.component';
import { StatusComponent } from './pages/admin/status/status.component';
import { BookDetailComponent } from './pages/client/book-detail/book-detail.component';
import { AboutComponent } from './pages/client/about/about.component';
import { PracticalInfoComponent } from './pages/client/practical-info/practical-info.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { BookDetailEditorComponent } from './pages/client/book-detail-edit/book-detail-edit.component';
import { BookRequestAdminComponent } from './pages/client/book-request-admin/book-request.admin.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,

    // Shared
    BreadcrumbsComponent,
    HeaderComponent,

    // UI
    ButtonComponent,
    HeaderUiComponent,
    TagComponent, 
    SvgComponent,

    // Layout
    ClientLayoutComponent,

    // Components
    BooksFilterComponent,
    BooksListComponent,

    // Pages: Public
    LoginComponent,
    RegisterComponent,
    HomeComponent,
    BooksComponent,
    BookDetailComponent,
    BookRequestComponent,
    AboutComponent,
    PracticalInfoComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    BookRequestAdminComponent,

    // Pages: Admin
    StatusComponent,
    AddBooksComponent,
    BookDetailEditorComponent
  ],
  imports: [
    BrowserModule,
    BarcodeScannerLivestreamModule,
    AppRoutingModule,
    HttpClientModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgbDropdownModule,
    NgbCollapseModule,
    NgbAlertModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
