import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from 'src/app/services/session.service';
import { LibraryService } from 'src/app/services/library.service';
import { Book, Copy, Publisher, Tag } from 'src/app/types/supabase/supabase.models';

@Component({
    selector: 'app-book-detail',
    templateUrl: './book-detail.component.html',
    styleUrls: ['./book-detail.component.scss']
})
export class BookDetailComponent implements OnInit {
    public book!: Book;
    public imageUrl: string = '';
    public breadcrumbs!: any[]
    public tags: string = '';
    public publishers: string = '';
    public isAvailable: boolean = false;

    public user$: Observable<any> = this.sessionService.user$;

    private get bookTitle() {
      return this.book?.title;
    }
    
    constructor(
        private libraryService: LibraryService,
        private sessionService: SessionService,
        private route: ActivatedRoute
    ) {}

  public ngOnInit(): void {
    this.book = this.route.snapshot.data['book'];
    this.breadcrumbs = [
      { label: 'Boeken', routerLink: ['../../'] },
      { label: this.bookTitle },
    ];
    if (this.book.uuid) {
        this.imageUrl = this.libraryService.getBookCoverUrl(this.book.uuid);
    }
    this.tags = this.book.tags
      .filter((tag: Tag) => tag.showInFrontend)
      .map((tag: Tag) => tag.displayName)
      .join(' | ') ?? '';
    this.publishers = this.book.publishers
      .map((publisher: Publisher) => publisher.name)
      .join(', ');
    this.isAvailable = this.book.copies
      .filter((copy: Copy) => copy.state === 1)
      .length > 0
  }
}
