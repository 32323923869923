import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { SupabaseService } from './supabase.service';
import { v4 as uuidv4 } from 'uuid';
import { Author, Book, Tag, UpdateBook } from '../types/supabase/supabase.models';

@Injectable({
    providedIn: 'root'
})
export class LibraryService {

    constructor(
        private supabaseService: SupabaseService
    ) {}

    public async getBook(id: number): Promise<any> {
        const select = `
            *,
            authors(*),
            tags(*),
            publishers(*),
            copies(*)
        `;
        const result = await this.supabaseService.client
            .from('books')
            .select(select)
            .eq('id', id)
            .maybeSingle<Book>();

        if (result.error || !result.data) {
            console.error(result.error);
            throw new Error('An error occured while fetching a book.');
        }
        const book = result.data;
        book.tags = book.tags.filter(tag => tag.showInFrontend);
        return book;
    }

    public async getBooks(filters: any): Promise<{ books: Book[], totalCount: number }> {
        const pageSize = 15;

        const queryParams = {
            'search_term': filters.searchValue?.length ? filters.searchValue : null,
            'author_ids': filters.authorIds?.length ? filters.authorIds : null,
            'tag_ids': filters.tagIds?.length ? filters.tagIds : null,
            'publisher_ids': filters.publisherIds?.length ? filters.publisherIds : null
        };
        const bookIdsResponse = await this.supabaseService.client.rpc('find_books', queryParams)
            .range((filters.page - 1) * pageSize, filters.page * pageSize - 1)
            .limit(pageSize);

        const resultCountResponse = await this.supabaseService.client.rpc('find_books_total_count', queryParams);

        const bookIds: number[] = bookIdsResponse.data.map((d: any) => d.id);
        const totalCount: number = resultCountResponse.data[0].id;

        const select = `
            *,
            authors(*),
            tags(*),
            publishers(*),
            copies(*)
        `;
        const { data, error } = await this.supabaseService.client
            .from('books')
            .select(select)
            .in('id', bookIds);

        if (error || !data) {
            console.error(error);
            throw new Error('An error occured while fetching a book.');
        }
        return { books: data, totalCount };
    }

    public async getLatestBook(): Promise<Book> {
        const result = await this.supabaseService.client
            .from('books')
            .select('*, authors(*)')
            .order('created', { ascending: false })
            .limit(1)
            .single();

        if (result.error) {
            console.error(result.error);
            throw new Error('An error occured while fetching a book.');
        }
        
        return result.data;
    }

    public async getBookCount(): Promise<number> {
        const result = await this.supabaseService.client.from('Book')
            .select('*', { count: 'exact' });
        if (result.data){
            return result['count'] as number;
        }
        return 0;
    }

    public async getDuplicates(): Promise<any[]> {
        return new Promise(() => []);
    }

    public async deleteBook(id: number): Promise<any> {
        // return this.delete(`/${id}`);
        return new Promise(() => true);
    }

    public async addImage(bookId: number, image: Blob): Promise<boolean> {
        const fileName = `${uuidv4()}.jpg`;
        await this.supabaseService.client.storage.from('covers')
            .upload(fileName, image, { contentType: 'image/png' });

        await this.supabaseService.client.from('Book')
            .update({ image: fileName })
            .eq('id', bookId);

        return true;
    }

    public async updateBook(book: UpdateBook, authors: Author[], tags: Tag[]): Promise<void> {
        book = { ...book, enableSync: false };
        await this.supabaseService.client.from('books')
            .update(book)
            .eq('id', book.id)

        await this.saveBookAuthors(book, authors);
        await this.saveBookTags(book, tags);
    }

    public async saveBookAuthors(book: UpdateBook, authors: Author[]): Promise<void> {
        const currentAuthorBooks = await this.supabaseService.client.from('authors_books')
            .select('authorId')
            .eq('bookId', book.id);

        let addedAuthors = [];
        if (currentAuthorBooks.data?.length) {
            const currentAuthors = currentAuthorBooks.data.map(a => a.authorId);
            const { data, error } = await this.supabaseService.client.from('authors_books')
                .delete()
                .eq('bookId', book.id)
                .not('authorId', 'in', `(${authors.map(a => a.id).join()})`);

            addedAuthors = authors.filter(a => !currentAuthors.includes(a));
        } else {
            addedAuthors = authors;
        }

        await this.supabaseService.client.from('authors_books')
            .upsert(addedAuthors.map(a => ({ bookId: book.id, authorId: a.id })))
    }

    public async saveBookTags(book: UpdateBook, tags: Tag[]): Promise<void> {
        const currentBookTags = await this.supabaseService.client.from('books_tags')
            .select('tagId')
            .eq('bookId', book.id);

        let addedTags = [];
        if (currentBookTags.data?.length) {
            const currentTags = currentBookTags.data.map(a => a.tagId);
            const { data, error } = await this.supabaseService.client.from('books_tags')
                .delete()
                .eq('bookId', book.id)
                .not('tagId', 'in', `(${tags.map(t => t.id).join()})`);

            addedTags = tags.filter(a => !currentTags.includes(a));
        } else {
            addedTags = tags;
        }

        await this.supabaseService.client.from('books_tags')
            .upsert(addedTags.map(t => ({ bookId: book.id, tagId: t.id })));
    }

    public async getPublishers(): Promise<any[]> {
        const result = await this.supabaseService.client.from('publishers').select();
        return result.data ?? [];
    }

    public async getLanguages(): Promise<any[]> {
        const result = await this.supabaseService.client.from('Language')
            .select();
        return result.data ?? [];
    }

    public getBookCoverUrl(fileName?: string): string {
        return environment.coverStorage + (fileName + '.jpg' ?? 'blank.png');
    }

    public async getFilterTags(): Promise<any[]> {
        const result = await this.supabaseService.client.from('tags')
            .select().eq('showInFrontend', true);
        return result.data ?? [];
    }

    public async getAuthors(): Promise<any[]> {
        const result = await this.supabaseService.client.from('authors')
            .select()
            .order('name', { ascending: true });
        return result.data ?? [];
    }

    public async requestBook(userId: string, copyId: number, token: string, body: any): Promise<any> {
    
        const { data, error } = await this.supabaseService.client
            .from('requests')
            .insert([{ 
                userId,
                availability: body.comments.availability,
                comments: body.comments.comments,
                copyId
             }]);

        console.log(data)
        console.log(error)
            
        return await this.supabaseService.client.functions.invoke('mail-request', {
            method: 'POST',
            body,
            headers: {
                Authorization: `Bearer ${token}`
            },
        });
    }
}