<div class="container p-3">
    <h1>Status</h1>

    <ul>
        <li>
            ✅ <strong>Scanner</strong>: Werkt, zou stabiel moeten zijn. Errors en/of onverwachte resultaten graag doorgeven!
        </li>
        <li>
            ✅ <strong>Boekenlijst</strong>: Werkt, zou stabiel moeten zijn. Errors graag doorgeven!
        </li>
        <li>
            ✅ <strong>Wijzigen</strong>: Werkt, zou stabiel moeten zijn. Errors graag doorgeven!
        </li>
        <li>
            ✅ <strong>Inloggen / Registreren</strong>: Werkt, zou stabiel moeten zijn. Errors graag doorgeven!
        </li>
        <li>
            ✅ <strong>Uitloggen</strong>: Werkt, zou stabiel moeten zijn. Errors graag doorgeven!
        </li>
        <li>
            ❗ <strong>Zoeken</strong>: Werkt, maar niet grondig getest. (Known issue: Paginatie na zoekopdracht nog glitchy)
        </li>
        <li>
            ❗ <strong>UI Config</strong>: Dropped
        </li>
        <li>
            🔨 <strong>Boeken reserveren</strong>: Work in progress!
        </li>
        <li>
            ❌ <strong>Blog</strong>: To do!
        </li>
        <li>
            ❌ <strong>Password Reset</strong>: To do!
        </li>
        <li>
            ❌ <strong>Events</strong>: To do!
        </li>
    </ul>
</div>