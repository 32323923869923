import { Injectable } from '@angular/core';
import { SupabaseService } from './supabase.service';

@Injectable({
    providedIn: 'root'
})
export class TextService   {
    constructor(private supabaseService: SupabaseService) {}

    public async getHomeText(): Promise<any> {
        const result = await this.supabaseService.client.from('admin_text')
            .select('*')
            .eq('id', 1)
            .limit(1)
            .single();

        return result.data;
    }

    public async getHowtoText(): Promise<any> {
        const result = await this.supabaseService.client.from('admin_text')
            .select('*')
            .eq('id', 2)
            .limit(1)
            .single();

        return result.data;
    }

    public async getAboutText(): Promise<any> {
        const result = await this.supabaseService.client.from('admin_text')
            .select('*')
            .eq('id', 3)
            .limit(1)
            .single();

        return result.data;
    }
}