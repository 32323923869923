<div class="container d-flex flex-column">
    <app-breadcrumbs *ngIf="breadcrumbs && breadcrumbs.length" [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
    <div class="container d-flex flex-column align-items-center justify-content-center my-3">
        <form *ngIf="bookForm" class="w-100 w-md-75 w-xl-50" [formGroup]="bookForm" (ngSubmit)="submitBook()">
            <div class="form-group py-3">
              <label for="bookTitle">Titel:</label>
              <input class="form-control" type="text" id="bookTitle" formControlName="bookTitle">
            </div>
          
            <div class="form-group py-3">
              <label for="authors">Auteurs:</label>
              <ng-select class="mt-1 mb-2" name="authors" [items]="authorOptions" formControlName="authors" placeholder="Auteurs" bindLabel="name" [multiple]="true"></ng-select>
            </div>
          
            <div class="form-group py-3">
              <label for="publisher">Uitgever:</label>
              <ng-select class="mt-1 mb-2" name="publishers" [items]="publisherOptions" formControlName="publisher" placeholder="Uitgeverij" bindLabel="name">
            </ng-select>
            </div>
          
            <div class="form-group py-3">
              <label for="description">Beschrijving:</label>
              <textarea class="form-control" id="description" formControlName="description" rows="15"></textarea>
            </div>
          
            <div class="form-group py-3">
              <label for="isbn">ISBN10:</label>
              <input class="form-control" type="text" id="isbn10" formControlName="isbn10">
            </div>
          
            <div class="form-group py-3">
              <label for="isbn">ISBN13:</label>
              <input class="form-control" type="text" id="isbn13" formControlName="isbn13">
            </div>
          
            <div class="form-group py-3">
              <label for="tags">Categorieën:</label>
              <ng-select class="mt-1 mb-2" name="tags" [items]="tagOptions" formControlName="tags" placeholder="Categorieën" bindLabel="displayName" [multiple]="true"></ng-select>
            </div>
          
            <div class="form-group py-3">
              <label for="publishedYear">Gepubliceerd op (jaar):</label>
              <input type="number" class="form-control" id="publishedYear" formControlName="publishedYear">
            </div>
          
            <div class="form-group py-3">
              <label for="pageCount">Aantal blz:</label>
              <input type="number" class="form-control" id="pageCount" formControlName="pageCount">
            </div>
          
            <!-- <div class="form-group py-3">
              <label for="image">Upload Image:</label>
              <input type="file"  class="form-control" id="image" (change)="onFileSelected($event)">
              <button class="btn" type="button" (click)="uploadImage()">Upload</button>
            </div> -->
          
            <button class="btn btn-primary" type="submit">Submit</button>
          </form>
    </div>
</div>